<template>
  <div class="top">
    <img class="pc" :src="require('@/assets/news/top/bg.png')" />
    <img class="moblie" :src="require('@/assets/news/top/bg-m.png')" />
    <div class="text">
      <div class="t1">新闻中心</div>
      <div class="t2">了解更多IOT行业资讯内容，拓展更多RFID技术解决方案</div>
      <a
        class="btn"
        target="_blank"
        href="https://clothing.cirteklink.com/shop/#/login"
        >服装RFID方案</a
      >
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .top {
    position: relative;

    @if ($searchWidth<1100) {
      margin-top: 3.2rem;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .t1 {
      // font-size: 0.6rem;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;

      @if ($searchWidth<1100) {
        font-size: 1rem;
      } @else {
        font-size: 0.6rem;
      }
    }

    .t2 {
      // font-size: 0.3rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        margin: 0.7rem 0 1.25rem;
        max-width: 14rem;
        text-align: center;
      } @else {
        font-size: 0.3rem;
        margin: 0.3rem 0 0.5rem;
      }
    }

    .btn {
      // height: 0.64rem;
      // line-height: 0.64rem;
      background: #5ec7b9;
      // border-radius: 0.32rem;
      // font-size: 0.24rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
      display: inline-block;
      // padding: 0 0.26rem;
      text-decoration: none;

      @if ($searchWidth<1100) {
        height: 2rem;
        line-height: 2rem;
        font-size: 0.7rem;
        border-radius: 1rem;
        padding: 0 1.2rem;
      } @else {
        height: 0.64rem;
        line-height: 0.64rem;
        font-size: 0.24rem;
        border-radius: 0.32rem;
        padding: 0 0.26rem;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
