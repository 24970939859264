<template>
  <top></top>
  <list></list>
  <!--  <div style="height: 100vh; overflow: hidden">-->
  <!--    <iframe-->
  <!--      id="iframe"-->
  <!--      height="100%"-->
  <!--      width="100%"-->
  <!--      src="http://www.cirteklink.com:8780/elementor-80/"-->
  <!--    ></iframe>-->
  <!--  </div>-->
  <div
    :style="{
      margin: '1rem 0 .4rem',
      height: '.02rem',
      background: '#E5E6EB',
    }"
  ></div>
  <common-footer />
</template>
<script>
import top from "./components/top";
import list from "./components/list";
// import axios from "axios";
import CommonFooter from "@components/footer.vue";
import { mapMutations } from "vuex";
export default {
  metaInfo: {
    title: "新闻中心_常达智能", // set a title
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "新闻中心",
      },
      {
        name: "description",
        content:
          "常达智能物联（深圳）有限公司为全资港资企业，专注于RFID核心技术、拥有自主研发团队，开发RFID系统平台/RFID产品与RFID项目解决方案，为客户提供一站式RFID解决方案和配套软硬件的开发与实施服务。",
      },
    ],
  },
  components: {
    CommonFooter,
    top,
    list,
  },
  computed: {},
  methods: {
    ...mapMutations([
      "setHeaderColorBlack",
      "setHeaderColorWhite",
      "setheaderTopBtnWhite",
    ]),
  },

  mounted() {
    this.setHeaderColorWhite();
    this.setheaderTopBtnWhite(true);
  },
  unmounted() {
    this.setHeaderColorBlack();
    this.setheaderTopBtnWhite(false);
  },
};
</script>
<style lang="scss" scoped></style>
